import {
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  orderBy,
  limit,
  where,
  addDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";

import { replacePlayersRefs, getMultipleRefs, drawTeams } from "./helpers";

import { GAMES_DB_COLLECTION, USERS_DB_COLLECTION } from "./CONSTANTS";

export const createGame = () => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    try {
      // const userRef1 = await doc(db, "users", "i16xeNGKoBbGBIvpdSj3OM9Y2Zt1");
      // const userRef2 = await doc(db, "users", "tU3uP0KzG0MbQ6uixlqIZUaPeWp1");
      addDoc(collection(db, GAMES_DB_COLLECTION), {
        name: "Deuses do Padel",
        venue: "Decathlon Parque das Nações",
        venueImg: "viva_padel",
        maxPlayers: 4,
        // players: [userRef1, userRef2, userRef1, userRef2],
        // teams: [
        //   { players: [0, 1], score: [7, 6, 3] },
        //   {
        //     players: [2, 3],
        //     score: [5, 4, 4],
        //   },
        // ],
        date: {
          duration: 5400000,
          timestamp: 1686769200000,
        },
      }).then((docRef) => {
        resolve(docRef.id);
      });
    } catch (error) {
      reject({
        type: "error",
        message: "Game creation was not possible. Please try again!",
      });
    }
  });
};

export const getUpcomingGames = () => {
  return new Promise((resolve, reject) => {
    try {
      // Get UpComing Games
      const q = query(
        collection(db, GAMES_DB_COLLECTION),
        orderBy("date.timestamp", "asc"),
        limit(10),
        where("date.timestamp", ">=", Date.now())
      );

      getDocs(q).then((querySnapshot) => {
        replacePlayersRefs(querySnapshot).then((data) => {
          resolve(data);
        });
      });
    } catch (error) {
      reject({
        type: "error",
        message: "No data found. Please try again!",
      });
    }
  });
};

export const getMultipleGames = (conditions) => {
  return new Promise((resolve, reject) => {
    try {
      const q = query(
        collection(db, GAMES_DB_COLLECTION),
        conditions && (conditions.order || conditions.orderBy)
          ? orderBy(
              conditions.orderBy ? conditions.orderBy : null,
              conditions.order ? conditions.order : null
            )
          : null,
        conditions && conditions.limit
          ? limit(conditions.limit ? conditions.limit : null)
          : null,
        conditions && conditions.where
          ? where(
              conditions.where && conditions.where.field
                ? conditions.where.field
                : null,
              conditions.where && conditions.where.operator
                ? conditions.where.operator
                : null,
              conditions.where && conditions.where.comparator
                ? conditions.where.comparator
                : null
            )
          : null
      );

      getDocs(q).then((querySnapshot) => {
        replacePlayersRefs(querySnapshot).then((data) => {
          resolve(data);
        });
      });
    } catch (error) {
      reject({
        type: "error",
        message: "No data found. Please try again!",
      });
    }
  });
};

export const gameRegistration = (userId, gameId) => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    try {
      // Get User Doc
      const userRef = await doc(db, USERS_DB_COLLECTION, userId);
      // Get Game Ref
      const gameRef = await doc(db, GAMES_DB_COLLECTION, gameId);
      getDoc(gameRef).then((gameSnap) => {
        let foundData = gameSnap.data();
        foundData.id = gameSnap.id;
        foundData.players = foundData.players
          ? [...foundData.players, userRef]
          : [userRef];

        if (foundData.players.length === foundData.maxPlayers) {
          drawTeams(foundData.players).then((res) => {
            let indexArr = [];
            res.sortedData.map((sortedPlayer) => {
              res.playersData.map((player, j) => {
                if (player.id === sortedPlayer.id) indexArr.push(j);
              });
            });

            let teamA = [indexArr[0], indexArr[3]];
            let teamB = [indexArr[1], indexArr[2]];

            foundData.teams = [
              {
                players: teamA,
              },
              { players: teamB },
            ];

            getMultipleRefs(foundData.players).then((playersData) => {
              updateDoc(gameRef, foundData).then(() => {
                resolve({
                  type: "success",
                  data: {
                    id: gameSnap.id,
                    ...foundData,
                    players: playersData,
                  },
                });
              });
            });
          });
        } else {
          getMultipleRefs(foundData.players).then((playersData) => {
            updateDoc(gameRef, foundData).then(() => {
              resolve({
                type: "success",
                data: {
                  id: gameSnap.id,
                  ...gameSnap.data(),
                  players: playersData,
                },
              });
            });
          });
        }
      });
    } catch (error) {
      reject({
        type: "error",
        message: error,
      });
    }
  });
};

export const cancelGameRegistration = (userId, gameId) => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    try {
      // Get User Doc
      const userRef = await doc(db, USERS_DB_COLLECTION, userId);
      // Get Game Ref
      const gameRef = await doc(db, GAMES_DB_COLLECTION, gameId);
      getDoc(gameRef).then((gameSnap) => {
        let foundData = gameSnap.data();
        foundData.id = gameSnap.id;
        if (foundData.teams) delete foundData.teams;

        const filteredPlayers = foundData.players.filter(
          (player) => player.id !== userRef.id
        );

        getMultipleRefs(filteredPlayers).then((playersData) => {
          setDoc(gameRef, {
            ...foundData,
            players: filteredPlayers,
          }).then(() => {
            resolve({
              type: "success",
              data: {
                ...foundData,
                players: playersData,
              },
            });
          });
        });
      });
    } catch (error) {
      reject({
        type: "error",
        message: error,
      });
    }
  });
};
