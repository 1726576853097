import { styled } from "@mui/material/styles";

const Wrapper = styled("div")({
  "& .MuiGrid-item": {
    display: "flex",
    justifyContent: "center",
  },
});

export default Wrapper;
