import { styled } from "@mui/material/styles";
import { theme } from "../../theme";

const TRANSITION_DURATION = 225;

const Wrapper = styled("div")({
  "& .slick-list, .slick-slider, .slick-track": {
    position: "relative",
    display: "block",
  },
  "& .slick-loading .slick-slide, .slick-loading .slick-track": {
    visibility: "hidden",
  },
  "& .slick-slider": {
    boxSizing: "border-box",
    userSelect: "none",
    touchAction: "pan-y",
  },
  "& .slick-list": {
    overflow: "hidden",
    margin: "0",
    cursor: "pointer",
  },
  "& .slick-list:focus": {
    outline: "0",
  },
  "& .slick-list.dragging": {
    cursor: "hand",
  },
  "& .slick-slider .slick-list, .slick-slider .slick-track": {
    transform: "translate3d(0, 0, 0)",
  },
  "& .slick-track": {
    top: "0",
    left: "0",
  },
  "& .slick-track:after, & .slick-track:before": {
    display: "table",
    content: '""',
  },
  "& .slick-track:after": {
    clear: "both",
  },
  "& .slick-slide": {
    display: "none",
    float: "left",
    height: "100%",
    minHeight: "1px",
  },
  "& .slick-slide img": {
    display: "block",
  },
  "& .slick-slide.slick-loading img": {
    display: "none",
  },
  "& .slick-slide.dragging img": {
    pointerEvents: "none",
  },
  "& .slick-initialized .slick-slide": {
    display: "block",
  },
  "& .slick-vertical .slick-slide": {
    display: "block",
    height: "auto",
    border: "1px solid transparent",
  },
  "& .slick-dots": {
    position: "absolute",
    padding: "0",
    bottom: "-25px",
    width: "100%",
    margin: "0",
    listStyle: "none",
    textAlign: "center",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .slick-dots li": {
    backgroundColor: theme.colors.white.darker,
    position: "relative",
    display: "inline-block",
    width: "4px",
    height: "4px",
    borderRadius: "50%",
    margin: "0 5px",
    padding: "0",
    cursor: "pointer",
    transition: `all ${TRANSITION_DURATION}ms; ease-in-out`,
  },
  "& .slick-dots li.slick-active": {
    backgroundColor: theme.colors.secondary.default,
    position: "relative",
    display: "inline-block",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    margin: "0 4px",
    padding: "0",
    cursor: "pointer",
    transition: `all ${TRANSITION_DURATION}ms; ease-in-out`,
  },
  "& .slick-dots li button": {
    display: "none",
  },
  "& .slick-slider.game-details .slick-list": {
    overflow: "unset",
  },
  "& .slick-slider .slick-slide": {
    transform: "translateX(0)",
    transition: `transform ${TRANSITION_DURATION}ms; ease-in-out`,
  },
  "& .slick-slider.game-details .slick-slide:not(.slick-active)": {
    transform: "translateX(5%)",
    transition: `transform ${TRANSITION_DURATION}ms; ease-in-out`,
  },
  "& .slick-slider .slick-slide .bg-img-wrapper": {
    transform: "translate(0%, 0%) scale(1)",
    transition: `transform ${TRANSITION_DURATION}ms; ease-in-out`,
  },
  "& .slick-slider.game-details .slick-slide.slick-active .bg-img-wrapper": {
    transform: "translate(4%, -30%) scale(1.2)",
    transition: `transform ${TRANSITION_DURATION}ms; ease-in-out`,
  },
  "& .slick-slider .slick-slide .bg-img-wrapper .bg-img": {
    filter: "contrast(1.2) brightness(1.2) saturate(1.2) opacity(1) blur(0px)",
    transition: `filter ${TRANSITION_DURATION}ms; ease-in-out`,
  },
  "& .slick-slider.game-details .slick-slide.slick-active .bg-img-wrapper .bg-img":
    {
      filter:
        "contrast(1.2) brightness(1.2) saturate(1.2) opacity(1) blur(3px)",
      transition: `filter ${TRANSITION_DURATION}ms; ease-in-out`,
    },
  "& .slick-slider .slick-slide .bg-img-wrapper .slide-header-content": {
    display: "flex",
  },
  "& .slick-slider.game-details .slick-slide.slick-active .bg-img-wrapper .slide-header-content":
    {
      display: "none",
    },
});

export default Wrapper;
