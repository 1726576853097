import PropTypes from "prop-types";

export const Check = ({ width, height, strokeColor, strokeWidth }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="check-icon"
    >
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
  );
};

Check.defaultProps = {
  strokeColor: "#000",
  strokeWidth: "2",
  width: "24px",
  height: "24px",
};

Check.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColor: PropTypes.string,
  strokeWidth: PropTypes.string,
};
