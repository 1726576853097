import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "appState",
  initialState: {
    value: {
      isLoading: false,
      message: {},
    },
  },
  reducers: {
    UPDATE_LOADING: (state, action) => {
      state.value.isLoading = action.payload;
    },
    ADD_MESSAGE: (state, action) => {
      state.value.message = {
        ...state.value.message,
        ...action.payload,
      };
    },
  },
});

export const { UPDATE_LOADING } = appSlice.actions;

export default appSlice.reducer;
