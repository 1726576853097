import { createSlice } from "@reduxjs/toolkit";

export const currentUserSlice = createSlice({
  name: "currentUserData",
  initialState: {
    value: {
      isLoggedIn: {},
      data: {},
    },
  },
  reducers: {
    LOGIN_USER: (state, action) => {
      state.value = {
        ...state.value,
        isLoggedIn: action.payload,
      };
    },
    UPDATE_USER: (state, action) => {
      state.value = {
        ...state.value,
        data: {
          ...state.value.data,
          ...action.payload,
        },
      };
    },
  },
});

export const { LOGIN_USER, UPDATE_USER } = currentUserSlice.actions;

export default currentUserSlice.reducer;
