import { lightenDarkenColor } from "./utils";

class Theme {
  constructor(colors, breakingPoints) {
    this.colors = {
      primary: "#02e3a7",
      secondary: "#22b4de",
      black: "#26292e",
      white: "#ffffff",
      ...colors,
    };
    this.breakingPoints = {
      xs: "0px",
      sm: "600px",
      md: "900px",
      lg: "1200px",
      xl: "1536px",
      ...breakingPoints,
    };
    this.responsiveCSSRules = {};
    this.columns = 12;
    this.spacing = 8;
    this.additionalColors = {
      warning: "#f44336",
    };
  }

  generateColorsPalette() {
    Object.keys(this.colors).map((key) => {
      return (this.colors = {
        ...this.colors,
        [key]: {
          default: this.colors[key],
          darker: lightenDarkenColor(this.colors[key], -50),
          dark: lightenDarkenColor(this.colors[key], -25),
          light: lightenDarkenColor(this.colors[key], 50),
          lighter: lightenDarkenColor(this.colors[key], 75),
          lightest: lightenDarkenColor(this.colors[key], 90),
        },
      });
    });
    return this.colors;
  }

  generateResponsiveRules() {
    Object.keys(this.breakingPoints).map((key) => {
      return (this.responsiveCSSRules = {
        ...this.responsiveCSSRules,
        [key]: `(min-width: ${this.breakingPoints[key]})`,
      });
    });
    return this.responsiveCSSRules;
  }

  createTheme() {
    this.generateColorsPalette();
    this.generateResponsiveRules();
  }
}

const theme = new Theme();
theme.createTheme();

export { theme };
