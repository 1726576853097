import { useNavigate } from "react-router-dom";

import { useAuth } from "../AuthProvider";

const Auth = () => {
  const userAuth = useAuth();
  const navigate = useNavigate();

  try {
    userAuth
      .loginUser()
      .then(() => {
        return navigate("/");
      })
      .catch((err) => {
        navigate("/login");
        console.log(err);
        throw new Error(err);
      });
  } catch (err) {
    navigate("/login");
    throw new Error(err);
  }
};

export default Auth;
