import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import { db, auth } from "../firebase";

import { USERS_DB_COLLECTION } from "./CONSTANTS";

export const createNewUser = (data) => {
  return new Promise((resolve, reject) => {
    try {
      setDoc(doc(db, USERS_DB_COLLECTION, data.uid), {
        email: data.email,
        displayName: data.email,
      }).then(() => {
        resolve({
          id: data.uid,
          email: data.email,
          displayName: data.email,
        });
      });
    } catch (error) {
      reject({
        type: "error",
        message: "Game creation was not possible. Please try again!",
      });
    }
  });
};

export const getPublicUserData = (userId) => {
  return new Promise((resolve, reject) => {
    try {
      let foundUserData;
      const docRef = doc(db, USERS_DB_COLLECTION, userId);
      getDoc(docRef).then((docSnap) => {
        foundUserData = {
          id: docSnap.id,
          ...docSnap.data(),
        };
        resolve(foundUserData);
      });
    } catch (error) {
      reject({
        type: "error",
        message: "User not found",
      });
    }
  });
};

export const getUserData = (user) => {
  return new Promise((resolve, reject) => {
    try {
      let foundUserData;
      const docRef = doc(db, USERS_DB_COLLECTION, user.uid);
      getDoc(docRef).then((docSnap) => {
        if (!docSnap.data()) resolve(null);
        foundUserData = {
          id: docSnap.id,
          ...docSnap.data(),
          displayName: user.displayName
            ? user.displayName
            : user.email.split("@")[0],
        };
        resolve(foundUserData);
      });
    } catch (error) {
      reject({
        type: "error",
        message: "User not found",
      });
    }
  });
};

export const updateUserData = (data) => {
  return new Promise((resolve, reject) => {
    try {
      // Update Firestore User Doc
      const docRef = doc(db, USERS_DB_COLLECTION, auth.currentUser.uid);
      delete data.id;
      updateDoc(docRef, data).then(() => {
        // Update Auth Profile
        updateProfile(auth.currentUser, {
          ...data,
        })
          .then(() => {
            resolve({
              type: "success",
              message: "Successfully updated user",
            });
          })
          .catch((error) => {
            reject({
              type: "error",
              message: error,
            });
          });
      });
    } catch (error) {
      reject({
        type: "error",
        message: error,
      });
    }
  });
};
