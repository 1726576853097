import React, { useEffect, createContext, useContext } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useProviderAuth } from "./useProviderAuth";
// import { UPDATE_LOADING } from "../store/AppSlice";
import { LOGIN_USER, UPDATE_USER } from "../store/CurrentUserSlice";
import { CLEAR_ALL_GAMES } from "../store/GamesSlice";
import { getUserData, createNewUser } from "../services";

const authContext = createContext();

export function AuthProvider({ children }) {
  const userAuth = useProviderAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        userAuth.setUser(user);
        let loggedInUserData = await getUserData(user);

        // if no loggedinUSerData create a new user
        if (!loggedInUserData) {
          let newUser = await createNewUser(user);
          loggedInUserData = newUser;
        }

        dispatch(UPDATE_USER(loggedInUserData));
        dispatch(
          LOGIN_USER({
            email: loggedInUserData.email,
            validUntil: Date.now() + 86400000,
          })
        );
      } else {
        dispatch(UPDATE_USER({}));
        dispatch(LOGIN_USER({}));
        dispatch(CLEAR_ALL_GAMES());
      }
    });
    return unsubscribe;
  }, [dispatch]);

  return (
    <authContext.Provider value={userAuth}>{children}</authContext.Provider>
  );
}

AuthProvider.defaultProps = {};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function useAuth() {
  return useContext(authContext);
}
