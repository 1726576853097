import { useState } from "react";
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
  signOut,
} from "firebase/auth";
import { auth } from "../firebase";
import { validateEmail, allowedEmails, actionCodeSettings } from "./CONSTANTS";

export function useProviderAuth() {
  const [user, setUser] = useState(null);

  const sendLoginEmail = (email) => {
    return new Promise((resolve, reject) => {
      try {
        // Check if valid email
        const isValid = validateEmail(email);
        if (!isValid) {
          reject({ type: "error", message: "Email inválido." });
        }

        // Check if Email is allowed
        const allowed = allowedEmails.includes(email);
        if (!allowed) {
          reject({
            type: "error",
            message:
              "Email não existe. Contacte o administrador do site para mais informações.",
          });
        }

        sendSignInLinkToEmail(auth, email, actionCodeSettings)
          .then(() => {
            window.localStorage.setItem(
              "user",
              JSON.stringify({
                email,
                validUntil: Date.now() + 86400000,
              })
            );
            resolve({
              type: "success",
              message: "Email enviado. Verifique a sua inbox.",
            });
          })
          .catch((error) => {
            reject({
              type: "error",
              code: error.code,
              message: error.message,
            });
          });
      } catch (error) {
        reject({
          type: "error",
          message: "System error. Please try again later!",
        });
      }
    });
  };

  const loginUser = () => {
    return new Promise((resolve, reject) => {
      try {
        if (isSignInWithEmailLink(auth, window.location.href)) {
          let userStorage = JSON.parse(window.localStorage.getItem("user"));
          if (!userStorage.email) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again.
            reject({
              type: "error",
              message: "No email found.",
            });
          }
          signInWithEmailLink(auth, userStorage.email, window.location.href)
            .then(async (result) => {
              setUser(result.user);
            })
            .then((result) => {
              resolve(result);
            })
            .catch(() => {
              reject({
                type: "error",
                message: "Login unsuccessful",
              });
            });
        }
      } catch (error) {
        reject({
          type: "error",
          message: error,
        });
      }
    });
  };

  const logoutUser = () => {
    return new Promise((resolve, reject) => {
      try {
        signOut(auth)
          .then(() => {
            window.localStorage.removeItem("user");
            setUser(null);
            resolve({
              type: "success",
              message: "User logout successful",
            });
          })
          .catch(() => {
            reject({
              type: "error",
              message: "Something went wrong. Please try again.",
            });
          });
      } catch (error) {
        reject({
          type: "error",
          message: "System error. Please try again later!",
        });
      }
    });
  };

  return {
    user,
    setUser,
    sendLoginEmail,
    loginUser,
    logoutUser,
  };
}
