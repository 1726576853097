import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Wrapper from "./Wrapper";
import { theme } from "../../theme";
import GameResult from "../GameResult";

const BUCKET_PATH = `https://storage.googleapis.com/padel-games-app.appspot.com`;

const GameParticipants = (props) => {
  const { players, teams, maxPlayers, avatarSize, handleIncognitoPlayerClick } =
    props;

  const getPlayers = () => {
    let foundEls = [];
    for (let i = 0; i < maxPlayers; i++) {
      foundEls.push(
        <Grid key={i} item xs={6}>
          {players[i] ? (
            <Link to={`/profile/${players[i].id}`}>
              <Stack spacing={1} sx={{ alignItems: "center" }}>
                <Avatar
                  alt={players[i].displayName}
                  src={
                    players[i].photoURL
                      ? `${BUCKET_PATH}/users/${players[i].photoURL}_200x200`
                      : ""
                  }
                  sx={{
                    width: `${avatarSize}px`,
                    height: `${avatarSize}px`,
                    margin: i > 0 ? "0" : "0",
                  }}
                />
                <Typography variant="caption" sx={{ textAlign: "center" }}>
                  {players[i].displayName}
                </Typography>
              </Stack>
            </Link>
          ) : (
            <Button
              onClick={handleIncognitoPlayerClick}
              sx={{ color: theme.colors.black.default }}
            >
              <Stack spacing={1} sx={{ alignItems: "center" }}>
                <Avatar
                  alt=""
                  src=""
                  sx={{
                    width: `${avatarSize}px`,
                    height: `${avatarSize}px`,
                    margin: i > 0 ? "0" : "0",
                  }}
                />
                <Typography variant="caption" sx={{ textAlign: "center" }}>
                  {`PLAYER ${i + 1}`}
                </Typography>
              </Stack>
            </Button>
          )}
        </Grid>
      );
    }
    return foundEls;
  };

  return (
    <Wrapper {...props}>
      {!teams ? (
        <Grid container spacing={3}>
          {getPlayers()}
        </Grid>
      ) : (
        <GameResult
          players={players}
          teams={teams}
          maxPlayers={maxPlayers}
          avatarSize={70}
        />
      )}

      {!teams && (
        <Box sx={{ margin: "3rem 0", color: theme.colors.black.lightest }}>
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            Doubles will be created when the game is full.
          </Typography>
        </Box>
      )}
    </Wrapper>
  );
};

GameParticipants.defaultProps = {};

GameParticipants.propTypes = {
  players: PropTypes.array,
  teams: PropTypes.array,
  maxPlayers: PropTypes.number,
  avatarSize: PropTypes.number,
  handleIncognitoPlayerClick: PropTypes.func,
};

export default GameParticipants;
