import { createGlobalStyle } from "styled-components";

import { theme } from "./index";

const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');

* {
  font-family: 'PT Sans', sans-serif;
}

html {
  font-size: 14px;
}
body {
  background-color: #F7F7F7;
  color: ${theme.colors.black.default};
  margin: 0;
  padding: 0;
}
#root {
  position: relative;
}
a {
  color: ${theme.colors.black.default};
  cursor: pointer;
  text-decoration: none;
}
.MuiBox-root.custom-input {
  height: 50px;
  margin-right: -1rem;
  cursor: pointer;
}
.MuiBox-root.custom-input.login input {
  font-size: 16px;
}
.MuiBox-root.custom-input svg {
  transition: all 200ms ease-in-out;
}
.MuiBox-root.custom-input:hover svg.edit-icon {
  stroke: ${theme.colors.secondary.default};
}
.MuiInputBase-root {
  position: relative;
}
.MuiInputBase-root::before {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background-color: ${theme.colors.black.lighter};
  width: 100%;
  height: 1px;
}
.MuiInputBase-root input {
  padding: 0;
}
.MuiInputBase-root .MuiInput-underline.Mui-disabled {
  color: ${theme.colors.black.default}
}
.MuiInputBase-root .MuiInput-underline.Mui-disabled::before {
  border-bottom-style: solid;
}
.MuiInputBase-root .MuiInput-underline input.Mui-disabled {
  -webkit-text-fill-color: ${theme.colors.black.default}
}
`;

export default GlobalStyles;
