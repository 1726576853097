import PropTypes from "prop-types";
import { Outlet, Navigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import { REDIRECT_PATH } from "./CONSTANTS";
import { useAuth } from "../auth/AuthProvider";
import Navbar from "../components/Navbar";

const ProtectedRoutes = () => {
  const userAuth = useAuth();
  const user = userAuth.user;
  const localUser = localStorage.getItem("user");
  const parsedLocalUser = JSON.parse(localUser);

  if (!user && (!parsedLocalUser || parsedLocalUser.validUntil < Date.now())) {
    return <Navigate to={`${REDIRECT_PATH}?status=expired`} replace />;
  }

  return (
    <>
      <Navbar />
      <Container maxWidth="sm" disableGutters sx={{ overflow: "hidden" }}>
        <Box sx={{ margin: "0" }}>
          <Outlet />
        </Box>
      </Container>
    </>
  );
};

ProtectedRoutes.defaultProps = {};

ProtectedRoutes.propTypes = {
  user: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default ProtectedRoutes;
