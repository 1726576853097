import { styled } from "@mui/material/styles";
import { theme } from "../../../theme";

const Wrapper = styled("div")({
  position: "relative",
  "& .game-details-back:hover": {
    backgroundColor: theme.colors.secondary.default,
  },
});

export default Wrapper;
