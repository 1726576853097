import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import Listing from "../../components/Listing";

import { getMultipleGames } from "../../services";
import { SET_GAMES_HISTORY } from "../../store/GamesSlice";

const History = () => {
  const dispatch = useDispatch();
  const gamesHistory = useSelector((state) => state.games.value.history);

  useEffect(() => {
    const conditions = {
      order: "desc",
      orderBy: "date.timestamp",
      limit: 10,
      where: {
        field: "date.timestamp",
        operator: "<=",
        comparator: Date.now(),
      },
    };
    getMultipleGames(conditions).then((res) => {
      dispatch(SET_GAMES_HISTORY(res));
    });
  }, [dispatch]);

  return (
    <Container disableGutters sx={{ position: "relative", minHeight: "100vh" }}>
      <Stack spacing={4} sx={{ padding: "2rem 0 60px 0" }}>
        <Box sx={{ margin: "2rem 0" }}>
          <Container>
            <Typography
              variant="h4"
              sx={{
                lineHeight: "1",
                fontWeight: "bold",
                margin: "0 0 2rem",
              }}
            >
              Games History
            </Typography>
          </Container>
          {gamesHistory && <Listing data={gamesHistory} />}
        </Box>
      </Stack>
    </Container>
  );
};

export default History;
