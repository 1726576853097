import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import Wrapper from "./Wrapper";
import { theme } from "../../../theme";
import PlayersAvatars from "../../PlayersAvatars";
import GameResult from "../../GameResult";
import { Pin, Calendar } from "../../Svg";

const Item = (props) => {
  const { id, date, name, venue, players, teams, maxPlayers } = props.item;
  const { handleClick, activeItem } = props;

  return (
    <Wrapper {...props}>
      <Card
        sx={{
          backgroundColor: "transparent",
          width: "100%",
          margin: "0 auto",
        }}
        onClick={() => handleClick(id)}
      >
        <CardHeader
          sx={{ backgroundColor: theme.colors.white.default }}
          avatar={
            <Avatar
              sx={{
                width: 52,
                height: 52,
                bgcolor: theme.colors.secondary.darker,
              }}
              aria-label="recipe"
            >
              <Stack sx={{ alignItems: "center" }} spacing={0.25}>
                <Typography
                  variant="p"
                  sx={{
                    lineHeight: "1",
                  }}
                >
                  {moment(date.timestamp).format("D")}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    lineHeight: "1",
                  }}
                >
                  {moment(date.timestamp).format("MMM").toUpperCase()}
                </Typography>
              </Stack>
            </Avatar>
          }
          title={
            <Typography
              variant="h6"
              sx={{
                fontWeight: "700",
                lineHeight: "2",
              }}
            >
              {name}
            </Typography>
          }
          subheader={
            <Stack
              className="header-details-wrapper"
              sx={{ justifyContent: "space-between" }}
            >
              <Stack spacing={1} className="header-details">
                <Stack
                  direction="row"
                  sx={{ gap: ".5rem", alignItems: "center" }}
                >
                  <Pin
                    strokeColor={theme.colors.secondary.default}
                    width="16px"
                    height="16px"
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{
                      lineHeight: "1",
                    }}
                  >
                    {venue}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{ gap: ".5rem", alignItems: "center" }}
                >
                  <Calendar
                    strokeColor={theme.colors.secondary.default}
                    width="16px"
                    height="16px"
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{
                      lineHeight: "1",
                    }}
                  >
                    {moment(date.timestamp).format("HH:mm")} -
                    {moment(date.timestamp)
                      .add(date.duration, "ms")
                      .format("HH:mm")}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                className="header-avatars"
                direction="row"
                sx={{
                  opacity: activeItem !== id ? 1 : 0,
                  transition: "opacity 200ms ease-in-out",
                }}
              >
                <PlayersAvatars
                  players={players}
                  maxPlayers={maxPlayers}
                  margin="0"
                />
              </Stack>
            </Stack>
          }
        />
        <Collapse in={activeItem === id} timeout="auto" unmountOnExit>
          <GameResult
            players={players}
            teams={teams}
            maxPlayers={maxPlayers}
            avatarSize={70}
          />
        </Collapse>
      </Card>
    </Wrapper>
  );
};

Item.defaultProps = {};

Item.propTypes = {
  item: PropTypes.object,
  handleClick: PropTypes.func,
  activeItem: PropTypes.string,
};

export default Item;
