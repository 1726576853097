import { styled } from "@mui/material/styles";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "16px",
  padding: "0",
  margin: "0",
  minHeight: "300px",
  [theme.breakpoints.up("sm")]: {
    gap: "64px",
  },
}));

export default Wrapper;
