import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const EmailSend = () => {
  return (
    <Container disableGutters>
      <Container sx={{ position: "relative", height: "100vh" }}>
        <Stack
          spacing={4}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              margin: "0 0 2rem",
              textAlign: "center",
            }}
          >
            Login email sent!
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: "300", textAlign: "center" }}
          >
            You can now close this page and check your email inbox for follow-up
            actions to login in the app!
          </Typography>
        </Stack>
      </Container>
    </Container>
  );
};

export default EmailSend;
