import React, { useState } from "react";
import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";

import Wrapper from "./Wrapper";
import Item from "./Item";

const Listing = (props) => {
  const { data } = props;
  const [activeItem, setActiveItem] = useState(null);

  const handleClick = (id) => {
    if (id === activeItem) setActiveItem(null);
    else setActiveItem(id);
  };

  return (
    <Wrapper>
      <Stack spacing={1}>
        {data.map((item, index) => (
          <Item
            key={index}
            item={item}
            activeItem={activeItem}
            handleClick={handleClick}
          />
        ))}
      </Stack>
    </Wrapper>
  );
};

Listing.defaultProps = {};

Listing.propTypes = {
  data: PropTypes.array,
};

export default Listing;
