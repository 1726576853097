import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import Wrapper from "./Wrapper";
import { theme } from "../../theme";
import PlayersAvatars from "../PlayersAvatars";
import CircularProgressWithCounter from "../CircularProgressWithCounter";

const GameRegistrationSnippet = (props) => {
  const { players, maxPlayers, height, handleRegistrationOpen, isRegistered } =
    props;

  return (
    <Wrapper {...props}>
      <Paper
        elevation={5}
        sx={{
          position: "fixed",
          bottom: "0",
          left: "0",
          right: "0",
          borderRadius: "30px 30px 0 0",
          height: `${height}px`,
          maxWidth: "600px",
          margin: "0 auto",
        }}
      >
        <Stack spacing={2} sx={{ height: "100%" }}>
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "space-around",
              height: "50%",
            }}
          >
            <CircularProgressWithCounter
              players={players}
              maxPlayers={maxPlayers}
              size={50}
            />
            <PlayersAvatars
              players={players}
              maxPlayers={maxPlayers}
              avatarSize={40}
            />
          </Stack>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50%",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "100%",
                height: "100%",
                boxShadow: "none",
                fontSize: "1.2rem",
                fontWeight: "700",
                borderRadius: "30px 30px 0 0",
                backgroundColor: isRegistered
                  ? "red"
                  : theme.colors.secondary.default,
                color: theme.colors.white.default,
              }}
              disabled={
                !isRegistered && players.length === maxPlayers ? true : false
              }
              onClick={handleRegistrationOpen}
            >
              {isRegistered
                ? "CANCEL REGISTRATION"
                : players.length === maxPlayers
                ? "CLOSED"
                : "JOIN"}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Wrapper>
  );
};

GameRegistrationSnippet.defaultProps = {};

GameRegistrationSnippet.propTypes = {
  players: PropTypes.array,
  maxPlayers: PropTypes.number,
  height: PropTypes.number,
  handleRegistrationOpen: PropTypes.func,
  isRegistered: PropTypes.bool,
};

export default GameRegistrationSnippet;
