import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import Wrapper from "./Wrapper";

const BUCKET_PATH = `https://storage.googleapis.com/padel-games-app.appspot.com`;

const NoScoreSnippet = ({ setsNum }) => {
  let els = [];
  for (let i = 0; i < setsNum; i++) {
    els.push(
      <Stack key={i} direction="row" spacing={2} sx={{ alignItems: "center" }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "300",
          }}
        >
          ?
        </Typography>
        <Typography variant="subtitle1">&mdash;</Typography>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "300",
          }}
        >
          ?
        </Typography>
      </Stack>
    );
  }
  return els;
};

const GameResult = (props) => {
  const { players, teams, avatarSize } = props;

  if (!players) return;

  return (
    <Wrapper {...props}>
      <Stack spacing={2}>
        {teams[0].players.map((playerIdx, index) => {
          return (
            <Link
              key={index}
              to={players[playerIdx] ? `/profile/${players[playerIdx].id}` : ""}
            >
              <Stack sx={{ alignItems: "center" }} spacing={0.5}>
                <Avatar
                  alt={
                    players[playerIdx].displayName
                      ? players[playerIdx].displayName
                      : ""
                  }
                  src={
                    players[playerIdx].photoURL
                      ? `${BUCKET_PATH}/users/${players[playerIdx].photoURL}_200x200`
                      : ""
                  }
                  sx={{
                    width: `${avatarSize}px`,
                    height: `${avatarSize}px`,
                    margin: index > 0 ? "0" : "0",
                  }}
                />
                <Typography variant="caption">
                  {players[playerIdx].displayName}
                </Typography>
              </Stack>
            </Link>
          );
        })}
      </Stack>
      <Stack spacing={1}>
        {teams && teams[0].score && teams[1].score ? (
          teams[0].score.map((num, index) => (
            <Stack
              key={index}
              direction="row"
              spacing={2}
              sx={{ alignItems: "center" }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: num > teams[1].score[index] ? "700" : "300",
                }}
              >
                {num}
              </Typography>
              <Typography variant="subtitle1">&mdash;</Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: teams[1].score[index] > num ? "700" : "300",
                }}
              >
                {teams[1].score[index]}
              </Typography>
            </Stack>
          ))
        ) : (
          <NoScoreSnippet setsNum={2} />
        )}
      </Stack>
      <Stack spacing={2}>
        {teams[1].players.map((playerIdx, index) => {
          return (
            <Link
              key={index}
              to={players[playerIdx] ? `/profile/${players[playerIdx].id}` : ""}
            >
              <Stack key={index} sx={{ alignItems: "center" }} spacing={0.5}>
                <Avatar
                  alt={
                    players[playerIdx].displayName
                      ? players[playerIdx].displayName
                      : ""
                  }
                  src={
                    players[playerIdx].photoURL
                      ? `${BUCKET_PATH}/users/${players[playerIdx].photoURL}_200x200`
                      : ""
                  }
                  sx={{
                    width: `${avatarSize}px`,
                    height: `${avatarSize}px`,
                    margin: index > 0 ? "0" : "0",
                  }}
                />
                <Typography variant="caption">
                  {players[playerIdx].displayName}
                </Typography>
              </Stack>
            </Link>
          );
        })}
      </Stack>
    </Wrapper>
  );
};

GameResult.defaultProps = {};

GameResult.propTypes = {
  players: PropTypes.array,
  teams: PropTypes.array,
  maxPlayers: PropTypes.number,
  avatarSize: PropTypes.number,
};

export default GameResult;
