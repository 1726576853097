import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

// import { createGame } from "../../services";
import EventsSlider from "../../components/EventsSlider";

import { useAuth } from "../../auth/AuthProvider";
import { getUpcomingGames } from "../../services";
import { UPDATE_LOADING } from "../../store/AppSlice";
import { SET_UPCOMING_GAMES } from "../../store/GamesSlice";

const Home = () => {
  const upcomingGames = useSelector((state) => state.games.value.upcoming);
  const isLoading = useSelector((state) => state.appSettings.value.isLoading);
  const dispatch = useDispatch();
  const userAuth = useAuth();

  useEffect(() => {
    if (userAuth.user) {
      dispatch(UPDATE_LOADING(true));
      getUpcomingGames().then((res) => {
        dispatch(SET_UPCOMING_GAMES(res));
        dispatch(UPDATE_LOADING(false));
      });
    }
  }, [userAuth.user, dispatch]);

  // const handleCreateGame = () => {
  //   createGame()
  //     .then((res) => console.log(res))
  //     .catch((err) => console.log(err));
  // };

  return (
    <Container disableGutters sx={{ position: "relative", minHeight: "100vh" }}>
      <Stack spacing={4} sx={{ padding: "2rem 0 60px 0" }}>
        <Box sx={{ margin: "2rem 0" }}>
          <Container>
            <Typography
              variant="h4"
              sx={{
                lineHeight: "1",
                fontWeight: "bold",
                margin: "0 0 2rem",
              }}
            >
              Upcoming Games
            </Typography>
          </Container>
          {isLoading ? (
            <Box>
              <Skeleton variant="rectangular" />
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          ) : (
            <EventsSlider data={upcomingGames} />
          )}
        </Box>

        {/* <Container>
          <button onClick={handleCreateGame}>Create a Game</button>
        </Container> */}
      </Stack>
    </Container>
  );
};

export default Home;
