import { styled } from "@mui/material/styles";
import { theme } from "../../theme";

const Wrapper = styled("div")({
  position: "fixed",
  zIndex: "999",
  height: "60px",
  bottom: "0",
  left: "0",
  right: "0",
  "& .MuiButtonBase-root svg": {
    transform: "scale(1)",
    transition: "transform 200ms ease-in-out",
  },
  "& .MuiButtonBase-root.Mui-selected svg": {
    transform: "scale(1.2)",
    transition: "transform 200ms ease-in-out",
    filter: "drop-shadow(0px 5px 15px rgb(0 0 0 / 0.4))",
    boxShadow: "0 50px 15px rgba(145, 92, 182, 0.4)",
  },
  "& .MuiButtonBase-root.Mui-selected": {
    color: theme.colors.primary.default,
    fontWeight: "bold",
  },
});

export default Wrapper;
