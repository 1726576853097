export const REDIRECT_PATH = "/login";

export const actionCodeSettings = {
  url: `${window.location.origin}/auth`,
  handleCodeInApp: true,
};

export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const allowedEmails = [
  "joao.miguel.morgado@gmail.com",
  "joao@thecoded.tech",
  "sara.pozzetti@gmail.com",
  "manueltorcatojorge@gmail.com",
  "miguelmorgadomonteiro@gmail.com",
  "nunopistolas@gmail.com",
  "ricardotasso@gmail.com",
];
