import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import Wrapper from "./Wrapper";
import { theme } from "../../theme";

const CircularProgressWithCounter = (props) => {
  const { players, maxPlayers, size } = props;

  return (
    <Wrapper {...props}>
      <Box
        sx={{
          padding: "1rem",
          position: "relative",
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <CircularProgress
          variant="determinate"
          size={50}
          value={(players.length / maxPlayers) * 100}
          sx={{
            width: "100%",
            height: "100%",
            color:
              players.length === maxPlayers
                ? "red"
                : theme.colors.secondary.light,
            backgroundColor: theme.colors.black.default,
            borderRadius: "50%",
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="subtitle2" component="div" color="#fff">
            <span
              style={{ fontSize: "1.2rem", fontWeight: "bold" }}
            >{`${players.length}`}</span>
            {`/${maxPlayers}`}
          </Typography>
        </Box>
      </Box>
    </Wrapper>
  );
};

CircularProgressWithCounter.defaultProps = {
  size: 50,
};

CircularProgressWithCounter.propTypes = {
  players: PropTypes.array,
  maxPlayers: PropTypes.number,
  size: PropTypes.number,
};

export default CircularProgressWithCounter;
