import PropTypes from "prop-types";

export const Arrow = ({ width, height, strokeColor, strokeWidth }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="arrow-icon"
    >
      <line x1="19" y1="12" x2="5" y2="12"></line>
      <polyline points="12 19 5 12 12 5"></polyline>
    </svg>
  );
};

Arrow.defaultProps = {
  strokeColor: "#000",
  strokeWidth: "2",
  width: "24px",
  height: "24px",
};

Arrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColor: PropTypes.string,
  strokeWidth: PropTypes.string,
};
