import { styled } from "@mui/material/styles";
import { theme } from "../../theme";

const Wrapper = styled("div")({
  margin: "2rem 0",
  padding: "0 0 60px 0",
  "& .MuiBox-root.custom-input.photo-url": {
    height: "unset",
    margin: "0",
  },
  "& .MuiBox-root.custom-input.photo-url .MuiInputBase-root": {
    display: "none",
  },
  "& .custom-input-btn-wrapper:hover": {
    backgroundColor: theme.colors.white.default,
  },
  "& .MuiInputBase-root.Mui-focused::before": {
    backgroundColor: theme.colors.secondary.default,
    height: "2px",
  },
  "& .custom-input input": {
    fontSize: "1rem",
    fontWeight: "400",
    height: "50px",
  },
  "& .custom-input.display-name .MuiInputBase-root::before": {
    content: "none",
  },
  "& .custom-input.display-name input": {
    textAlign: "center",
    fontSize: "1.25rem",
    fontWeight: "700",
  },
  "& .custom-input.photo-url input": {
    position: "absolute",
    display: "none",
  },
  "& .MuiButtonBase-root.MuiButton-root.MuiButton-contained:hover": {
    backgroundColor: theme.colors.secondary.darker,
  },
});

export default Wrapper;
