import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useAuth } from "../AuthProvider";
import { theme } from "../../theme";

const Wrapper = styled("div")({
  width: "100vw",
  height: "100vh",
  "& .custom-input .MuiFormControl-root": {
    height: "unset",
  },
  "& .custom-input .MuiFormLabel-root": {
    transform: "translate(0, 10px) scale(1)",
  },
  "& .custom-input .MuiFormLabel-root.Mui-focused, & .custom-input .MuiFormLabel-root.Mui-error":
    {
      transform: "translate(0, -10px) scale(.75)",
      zIndex: 1,
      color: theme.colors.secondary.default,
    },
  "& .custom-input .MuiFormLabel-root.Mui-error": {
    color: "#d32f2f",
  },
  "& .custom-input .MuiInputBase-root": {
    marginTop: "0",
    height: "40px",
  },
  "& .custom-input .MuiInputBase-root:hover::before": {
    backgroundColor: theme.colors.secondary.default,
    borderBottom: `1px solid ${theme.colors.secondary.default}`,
  },
  "& .custom-input .MuiInputBase-root.Mui-focused::after": {
    borderBottom: `2px solid ${theme.colors.secondary.default}`,
  },
  "& .custom-input .MuiInputBase-root.Mui-focused::before": {
    backgroundColor: theme.colors.secondary.default,
    borderBottom: `1px solid ${theme.colors.secondary.default}`,
  },
  "& .custom-input .MuiInputBase-root.Mui-error::after": {
    borderBottom: `2px solid #d32f2f`,
  },
  "& .custom-input .MuiInputBase-root.Mui-error::before": {
    backgroundColor: "#d32f2f",
    borderBottom: `1px solid #d32f2f`,
  },
  "& .custom-input .MuiInputBase-input": {
    height: "100%",
    backgroundColor: theme.colors.white.default,
  },
  "& .custom-input .MuiInputBase-input:hover": {
    borderBottom: `1px solid ${theme.colors.secondary.default}`,
  },
  "& input:-internal-autofill-selected": {
    backgroundColor: "#fff !important",
    boxShadow: "0 0 0px 1000px white inset",
  },
  "& .submit-btn": {
    backgroundColor: theme.colors.secondary.dark,
  },
  "& .submit-btn:hover": {
    backgroundColor: theme.colors.secondary.default,
  },
});

const Login = () => {
  const userAuth = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [helperText, setHelperText] = useState(null);

  const handleChange = (e) => {
    if (helperText) setHelperText(null);
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    userAuth
      .sendLoginEmail(email)
      .then(() => navigate("/email-sent"))
      .catch((err) => setHelperText(err));
  };

  return (
    <Wrapper>
      <Container
        disableGutters
        sx={{
          maxWidth: "1440px",
          height: "100%",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "url(/assets/img/background.png)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            backgroundSize: "cover",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              width: "90%",
              maxWidth: "500px",
            }}
          >
            <Stack spacing={4} sx={{ width: "90%", margin: "2rem 0" }}>
              <Typography
                variant="h4"
                sx={{ textAlign: "center", fontWeight: "700" }}
              >
                Sign In
              </Typography>
              <Stack
                spacing={1}
                sx={{ height: "100%", width: "100%", alignItems: "center" }}
              >
                <Box
                  className="custom-input login"
                  sx={{
                    margin: "0 !important",
                    width: "80%",
                  }}
                >
                  <TextField
                    label={"Email"}
                    type={"email"}
                    variant="standard"
                    value={email}
                    onChange={handleChange}
                    error={
                      helperText && helperText.type === "error" ? true : false
                    }
                    helperText={helperText ? helperText.message : ""}
                    required
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  className="submit-btn"
                >
                  Sign In
                </Button>
              </Stack>
            </Stack>
          </Box>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Login;
