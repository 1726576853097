import React from "react";
import PropTypes from "prop-types";

import Avatar from "@mui/material/Avatar";

import Wrapper from "./Wrapper";

const BUCKET_PATH = `https://storage.googleapis.com/padel-games-app.appspot.com`;

const PlayersAvatars = (props) => {
  const { players, avatarSize } = props;

  return (
    <Wrapper {...props}>
      {players.map((player, index) => (
        <Avatar
          key={index}
          className="player-avatar"
          alt={player && player.displayName ? player.displayName : ""}
          src={
            player && player.photoURL
              ? `${BUCKET_PATH}/users/${player.photoURL}_200x200`
              : ""
          }
          sx={{
            width: `${avatarSize}px`,
            height: `${avatarSize}px`,
          }}
        />
      ))}
    </Wrapper>
  );
};

PlayersAvatars.defaultProps = {
  avatarSize: 30,
};

PlayersAvatars.propTypes = {
  players: PropTypes.array,
  maxPlayers: PropTypes.number,
  avatarSize: PropTypes.number,
  margin: PropTypes.string,
};

export default PlayersAvatars;
