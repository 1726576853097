import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import Wrapper from "./Wrapper";
import { theme } from "../../../theme";
import PlayersAvatars from "../../PlayersAvatars";
import CircularProgressWithCounter from "../../CircularProgressWithCounter";

const BUCKET_PATH = `https://storage.googleapis.com/padel-games-app.appspot.com`;

const Slide = (props) => {
  const { venueImg, players, maxPlayers, date, venue } = props.item;
  const { handleOpen } = props;

  return (
    <Wrapper {...props} onClick={() => handleOpen(props.item)}>
      <Box
        className="bg-img-wrapper"
        sx={{
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          borderRadius: "30px",
          overflow: "hidden",
          position: "relative",
          height: "300px",
          zIndex: "0",
        }}
      >
        <div
          className="bg-img"
          style={{
            backgroundImage: `url(${BUCKET_PATH}/venues/${venueImg}_1024x1024.webp)`,
          }}
        />
        <Stack
          className="slide-header-content"
          sx={{
            height: "100%",
            width: "100%",
            gap: "1rem",
            justifyContent: "space-between",
          }}
        >
          <CircularProgressWithCounter
            players={players}
            maxPlayers={maxPlayers}
            size={50}
          />
          <Stack
            direction="row"
            sx={{ padding: "1rem", justifyContent: "space-between" }}
          >
            <Stack direction="row">
              <PlayersAvatars
                players={players}
                maxPlayers={maxPlayers}
                avatarSize={40}
              />
            </Stack>
            <Box>
              <Chip
                label={players.length === maxPlayers ? "Closed" : "Join"}
                sx={{
                  fontSize: "14px",
                  backgroundColor:
                    players.length === maxPlayers
                      ? "red"
                      : theme.colors.secondary.light,
                  color:
                    players.length === maxPlayers
                      ? "#fff"
                      : theme.colors.black.default,
                  minWidth: "100px",
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Stack
        direction="row"
        sx={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          margin: "1rem 0",
          gap: ".5rem",
        }}
      >
        <Container disableGutters sx={{ width: "20%" }}>
          <Stack
            sx={{
              width: "80px",
              height: "80px",
              maxWidth: "100%",
              maxHeight: "100%",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: theme.colors.primary.default,
              borderRadius: "10px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                lineHeight: "1",
                fontWeight: "700",
              }}
            >
              {moment(date.timestamp).format("DD")}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                lineHeight: "1",
              }}
            >
              {moment(date.timestamp).format("MMM")}
            </Typography>
          </Stack>
        </Container>
        <Stack
          sx={{
            width: "90%",
            gap: ".5rem",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              lineHeight: "1",
              fontWeight: "700",
            }}
          >
            {venue}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              lineHeight: "1",
            }}
          >
            {moment(date.timestamp).format("HH:mm")} -
            {moment(date.timestamp).add(date.duration, "ms").format("HH:mm")}
          </Typography>
        </Stack>
      </Stack>
    </Wrapper>
  );
};

Slide.defaultProps = {};

Slide.propTypes = {
  item: PropTypes.object,
  handleOpen: PropTypes.func,
};

export default Slide;
