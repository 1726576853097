import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./AppSlice";
import currentUserReducer from "./CurrentUserSlice";
import gamesReducer from "./GamesSlice";

export default configureStore({
  reducer: {
    appSettings: appReducer,
    currentUser: currentUserReducer,
    games: gamesReducer,
  },
});
