import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";

import Wrapper from "./Wrapper";
import { SET_ACTIVE_GAME } from "../../store/GamesSlice";

const Navbar = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser.value.data);
  const activeGame = useSelector((state) => state.games.value.activeGame);
  const location = useLocation();
  const path = location.pathname.substring(1).split("/")[0];
  const urlArr = ["", "history", "profile"];
  const value = urlArr.indexOf(path);

  const handleChange = () => {
    if (activeGame) dispatch(SET_ACTIVE_GAME(null));
  };

  return (
    <Wrapper {...props}>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation value={value} onChange={handleChange}>
          <BottomNavigationAction
            icon={<SportsTennisIcon />}
            component={NavLink}
            to={"/"}
          />
          <BottomNavigationAction
            icon={<CalendarMonthIcon />}
            component={NavLink}
            to={"/history"}
          />
          <BottomNavigationAction
            icon={<PersonIcon />}
            component={NavLink}
            to={`/profile/${currentUser.id}`}
          />
        </BottomNavigation>
      </Paper>
    </Wrapper>
  );
};

export default Navbar;
