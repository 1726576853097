import { createSlice } from "@reduxjs/toolkit";

export const gamesDataSlice = createSlice({
  name: "gamesData",
  initialState: {
    value: {
      activeGame: null,
      upcoming: [],
      history: [],
    },
  },
  reducers: {
    SET_ACTIVE_GAME: (state, action) => {
      if (action.payload && action.payload.id) {
        const gameIndex = state.value.upcoming.findIndex(
          (game) => game.id === action.payload.id
        );
        state.value.activeGame = state.value.upcoming[gameIndex];
      } else {
        state.value.activeGame = null;
      }
    },
    UPDATE_GAME_DATA: (state, action) => {
      const gameIndex = state.value.upcoming.findIndex(
        (game) => game.id === action.payload.id
      );
      state.value.upcoming[gameIndex] = { ...action.payload };
    },
    SET_UPCOMING_GAMES: (state, action) => {
      state.value.upcoming = action.payload;
    },
    SET_GAMES_HISTORY: (state, action) => {
      state.value.history = action.payload;
    },
    CLEAR_ALL_GAMES: (state) => {
      state.value = {
        upcoming: [],
        history: [],
      };
    },
  },
});

export const {
  SET_ACTIVE_GAME,
  UPDATE_GAME_DATA,
  SET_UPCOMING_GAMES,
  SET_GAMES_HISTORY,
  CLEAR_ALL_GAMES,
} = gamesDataSlice.actions;

export default gamesDataSlice.reducer;
