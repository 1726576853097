import { styled } from "@mui/material/styles";

const Wrapper = styled("div")({
  borderRadius: "30px",
  overflow: "hiddden",
  position: "relative",
  width: "92%",
  "& .bg-img": {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "100%",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "30px",
    filter: "contrast(1.2) brightness(1.2) saturate(1.2) opacity(0.8)",
    zIndex: "-1",
  },
});

export default Wrapper;
