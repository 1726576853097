import { styled } from "@mui/material/styles";

const Wrapper = styled("div")(({ avatarSize }) => ({
  display: "flex",
  flexDirection: "row",
  "& .player-avatar": {
    margin: avatarSize ? `0 0 0 -${avatarSize / 3}px` : "0",
  },
  "& .player-avatar:nth-of-type(1)": {
    margin: "0",
  },
}));

export default Wrapper;
