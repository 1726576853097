import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import Wrapper from "./Wrapper";
import { theme } from "../../theme";
// import { getSingleGame } from "../../services";
// import { SET_GAMES_HISTORY } from "../../store/GamesSlice";

// const BUCKET_PATH = `https://storage.googleapis.com/padel-games-app.appspot.com`;

const Game = (props) => {
  const upcomingGames = useSelector((state) => state.games.value.upcoming);
  let { gameId } = useParams();
  const navigate = useNavigate();
  const [game, setGame] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [touchPosition, setTouchPosition] = useState(null);

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientY;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientY;
    const diff = touchDown - currentTouch;

    if (diff < -10) {
      setIsOpen(false);
      navigate("/");
    }

    setTouchPosition(null);
  };

  useEffect(() => {
    upcomingGames.filter((game) => {
      if (game.id === gameId) {
        setGame(game);
        setIsOpen(true);
        return true;
      }
      return false;
    });
  }, [gameId, game, upcomingGames]);

  return (
    <Wrapper
      {...props}
      style={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: "100vh",
        zIndex: "99999",
      }}
    >
      <Container
        disableGutters
        sx={{
          position: "absolute",
          left: "0",
          right: "0",
          bottom: isOpen ? "0" : "-70vh",
          transition: "all 300ms ease-in-out",
        }}
      >
        <Paper
          sx={{
            boxShadow:
              "-3px 3px -1px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
            height: "70vh",
            backgroundColor: theme.colors.white.default,
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          {game && game.venue}
        </Paper>
      </Container>
    </Wrapper>
  );
};

export default Game;
