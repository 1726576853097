import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytesResumable } from "firebase/storage";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

import Wrapper from "./Wrapper";
import { theme } from "../../theme";
import { storage } from "../../firebase";
import { UPDATE_USER } from "../../store/CurrentUserSlice";
import { updateUserData, getPublicUserData } from "../../services";
import { useAuth } from "../../auth/AuthProvider";
import { Edit, Check } from "../../components/Svg";

const MAX_FILE_SIZE_MB = 2;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/webp"];
const BUCKET_PATH = `https://storage.googleapis.com/padel-games-app.appspot.com`;

const validateImageUpload = (file, allowedTypes, maxSize) => {
  let error;
  let allowedFileTpye = false;
  // CHECK IF CANCELLED UPLOAD
  if (!file) {
    error = "Nenhum ficheiro selecionado.";
    return error;
  }
  // CHECK FILE TYPE
  allowedTypes.map((type) => {
    if (file.type === type) allowedFileTpye = true;
    return allowedFileTpye;
  });
  if (!allowedFileTpye) error = "Tipo de ficheiro não suportado.";
  // CHECK FILE SIZE
  if (allowedFileTpye && file.size > maxSize)
    error = `Ficheiro excede tamanho máximo permitido de ${MAX_FILE_SIZE_MB}mb.`;
  return error;
};

const Profile = (props) => {
  const user = useSelector((state) => state.currentUser.value.data);
  const dispatch = useDispatch();
  const inputsRef = useRef({});
  const navigate = useNavigate();
  const userAuth = useAuth();
  const { userId } = useParams();
  const [editorMode, setEditorMode] = useState(false);
  const [updatedUser, setUpdatedUser] = useState();
  const [editingField, setEditingField] = useState(null);
  const [file, setFile] = useState();
  const [helperText, setHelperText] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (userId === user.id) {
      setUpdatedUser(user);
      setEditorMode(true);
    } else {
      getPublicUserData(userId).then((res) => setUpdatedUser(res));
    }
  }, [user, dispatch]);

  const handleChange = (e, key) => {
    const newUserData = {
      ...updatedUser,
      [key]: e.target.value,
    };
    setUpdatedUser(newUserData);
  };

  const handleEdit = (inputName) => {
    if (editingField === inputName) {
      setEditingField(null);
      return;
    }

    setEditingField(inputName);
    if (inputName === "photo-url")
      inputsRef.current[inputName].firstChild.click();
    else {
      inputsRef.current[inputName].firstChild.focus();
    }
  };

  const handleFocus = (inputName) => {
    setEditingField(inputName);
  };

  const handleBlur = () => {
    setEditingField(null);
  };

  const handleFileChange = async (e) => {
    if (!editingField) setEditingField("photo-url");

    const file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // VALIDATE FILE
      const imageErrors = validateImageUpload(
        file,
        ALLOWED_FILE_TYPES,
        MAX_FILE_SIZE_BYTES
      );

      // RETURN IF ERRORS IN VALIDATION
      if (imageErrors) {
        // SET ERRORS
        setHelperText({ ...helperText, image: imageErrors });
        return;
      }

      // CLEAN PREVIOUS ERRORS
      delete helperText.image;
      setHelperText(helperText);

      // UPDATE IMAGE PREVIEW DATA
      setFile({
        previewURL: URL.createObjectURL(file),
        name: file.name,
        file,
      });
      // UPDATE USER DATA
      setUpdatedUser({
        ...updatedUser,
        photoURL: uuidv4({ buffer: file }),
      });
      setEditingField(null);
    };
  };

  const handleSaveChanges = async () => {
    if (!editorMode) return;

    setIsUpdating(true);

    updateUserData(updatedUser)
      .then(() => {
        // If profile picture change, upload it to storage
        if (file) {
          const storageRef = ref(storage, `/users/${updatedUser.photoURL}`);

          // progress can be paused and resumed. It also exposes progress updates.
          // Receives the storage reference and the file to upload.
          const uploadTask = uploadBytesResumable(storageRef, file.file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              console.log(snapshot);
            },
            (err) => {
              setHelperText({ ...helperText, image: err });
              setIsUpdating(false);
              return;
            },
            () => {
              dispatch(UPDATE_USER(updatedUser));
              setIsUpdating(false);
              setHelperText({
                ...helperText,
                success: "User updated successfully",
              });
              setTimeout(() => {
                delete helperText.success;
                setHelperText(helperText);
              }, 2000);
            }
          );
        } else {
          dispatch(UPDATE_USER(updatedUser));
          setIsUpdating(false);
          setHelperText({
            ...helperText,
            success: "User updated successfully",
          });
          setTimeout(() => {
            delete helperText.success;
            setHelperText(helperText);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsUpdating(false);
      });
  };

  const handleLogout = () => {
    userAuth.logoutUser().then(() => navigate("/login"));
  };

  if (!updatedUser) {
    return (
      <div>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <Wrapper {...props}>
      <Container>
        <Stack spacing={4}>
          <Stack>
            <Stack
              spacing={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                className="custom-input photo-url"
                sx={{ overflow: "hidden", position: "relative" }}
              >
                <InputBase
                  ref={(element) => (inputsRef.current["photo-url"] = element)}
                  type={"file"}
                  variant="standard"
                  value={""}
                  onChange={handleFileChange}
                />

                <Avatar
                  alt={updatedUser.displayName}
                  src={
                    file && file.previewURL
                      ? file.previewURL
                      : updatedUser.photoURL
                      ? `${BUCKET_PATH}/users/${updatedUser.photoURL}_200x200`
                      : ""
                  }
                  sx={{ width: 120, height: 120 }}
                />
                {editorMode && (
                  <Box
                    className="custom-input-btn-wrapper"
                    onClick={() => handleEdit("photo-url")}
                    sx={{
                      position: "absolute",
                      right: "0",
                      bottom: "0",
                      borderRadius: "50%",
                      width: "32px",
                      height: "32px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: theme.colors.secondary.default,
                      transition: "all 200ms ease-in-out",
                    }}
                  >
                    <Edit
                      strokeColor={theme.colors.white.default}
                      strokeWidth="1"
                      width="20px"
                      height="20px"
                    />
                  </Box>
                )}
              </Box>
              <Box sx={{ height: "20px", margin: ".5rem" }}>
                {helperText && helperText.image ? helperText.image : ""}
              </Box>
              <Container sx={{ margin: "0" }}>
                <Box
                  className="custom-input display-name"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <InputBase
                    ref={(element) =>
                      (inputsRef.current["displayName"] = element)
                    }
                    type={"text"}
                    variant="standard"
                    value={
                      updatedUser.displayName ? updatedUser.displayName : ""
                    }
                    onChange={(e) => handleChange(e, "displayName")}
                    onFocus={() => handleFocus("displayName")}
                    onBlur={handleBlur}
                    required
                    disabled={editorMode ? false : true}
                  />
                  {editorMode && (
                    <Box
                      onClick={() => handleEdit("displayName")}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "20px",
                        width: "20px",
                      }}
                    >
                      {editingField === "displayName" ? (
                        <Check
                          strokeColor={theme.colors.secondary.default}
                          strokeWidth={"2"}
                        />
                      ) : (
                        <Edit
                          strokeColor={theme.colors.black.lighter}
                          strokeWidth={"1"}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Container>
              <Container>
                <Box
                  className="custom-input email"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                  }}
                >
                  <InputBase
                    ref={(element) => (inputsRef.current["email"] = element)}
                    type={"email"}
                    variant="standard"
                    value={updatedUser.email ? updatedUser.email : ""}
                    onChange={(e) => handleChange(e, "email")}
                    onFocus={() => handleFocus("email")}
                    onBlur={handleBlur}
                    required
                    disabled
                    sx={{ width: "400px", padding: "0 15px" }}
                  />
                  {/* <Box
                onClick={() => handleEdit("email")}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                {editingField === "email" ? (
                  <Check
                    strokeColor={theme.colors.secondary.default}
                    strokeWidth={"2"}
                  />
                ) : (
                  <Edit
                    strokeColor={theme.colors.black.lighter}
                    strokeWidth={"1"}
                  />
                )}
              </Box> */}
                </Box>
              </Container>
            </Stack>
          </Stack>
          <Container disableGutters sx={{ padding: "2rem 0" }}>
            {updatedUser.stats && updatedUser.stats && (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{ display: "center", justifyContent: "center" }}
                >
                  <Stack
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80px",
                      width: "80px",
                      backgroundColor: theme.colors.primary.light,
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      {updatedUser.stats.matches}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        textAlign: "center",
                        lineHeight: "1.5",
                        fontSize: ".7rem",
                      }}
                    >
                      Matches
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{ display: "center", justifyContent: "center" }}
                >
                  <Stack
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80px",
                      width: "80px",
                      backgroundColor: theme.colors.primary.light,
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      {updatedUser.stats.setsWon}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        textAlign: "center",
                        lineHeight: "1.5",
                        fontSize: ".7rem",
                      }}
                    >
                      Total Sets Won
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{ display: "center", justifyContent: "center" }}
                >
                  <Stack
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80px",
                      width: "80px",
                      backgroundColor: theme.colors.primary.light,
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "700",
                      }}
                    >
                      {updatedUser.stats.wonSetsPerMatch}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        textAlign: "center",
                        lineHeight: "1.5",
                        fontSize: ".7rem",
                      }}
                    >
                      Sets per Match
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Container>
          {editorMode && (
            <Stack sx={{ alignItems: "flex-start" }} spacing={3}>
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  sx={{ alignItems: "center", gap: "1rem" }}
                >
                  <Button
                    variant="contained"
                    onClick={handleSaveChanges}
                    sx={{ backgroundColor: theme.colors.secondary.default }}
                  >
                    Save changes
                  </Button>
                  {isUpdating && (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress size={25} />
                    </Box>
                  )}
                </Stack>
                <Typography variant="caption" component="div">
                  {helperText.success && helperText.success}
                </Typography>
              </Stack>
              <Box>
                <Button variant="text" onClick={handleLogout} color="error">
                  Logout
                </Button>
              </Box>
            </Stack>
          )}
        </Stack>
      </Container>
    </Wrapper>
  );
};

export default Profile;
