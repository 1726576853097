import { getDoc } from "firebase/firestore";

export const getMultipleRefs = (refsArr) => {
  const promises = refsArr.map(async (ref) => {
    const docSnap = await getDoc(ref);
    let foundData = docSnap.data();
    foundData.id = docSnap.id;
    return foundData;
  });

  return Promise.all(promises).then((res) => res);
};

export const drawTeams = (players) => {
  return new Promise((resolve, reject) => {
    try {
      getMultipleRefs(players).then((playersData) => {
        let sortedData = [...playersData];
        sortedData.sort((a, b) => {
          let aPerformance = a.stats.setsWon / a.stats.matches;
          let bPerformance = b.stats.setsWon / b.stats.matches;
          const res =
            aPerformance < bPerformance
              ? 1
              : bPerformance < aPerformance
              ? -1
              : 0;
          return res;
        });

        resolve({ playersData, sortedData });
      });
    } catch (error) {
      reject({
        type: "error",
        message: error,
      });
    }
  });
};

export const replacePlayersRefs = (docs) => {
  let foundDocs = [];
  docs.forEach(async (doc) => {
    let foundDoc = doc.data();
    foundDoc.id = doc.id;
    foundDocs.push(foundDoc);
  });

  const promises = foundDocs.map(async (doc) => {
    let foundDoc = {};
    let teamsData = [];
    if (doc.players) teamsData = await getMultipleRefs(doc.players);
    foundDoc = { ...doc, players: teamsData };
    return foundDoc;
  });

  return Promise.all(promises).then((res) => res);
};
