import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Slider from "react-slick";

import Wrapper from "./Wrapper";
import Slide from "./Slide";
import EventDetail from "./EventDetail";
import { SET_ACTIVE_GAME } from "../../store/GamesSlice";

const EventsSlider = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const activeGame = useSelector((state) => state.games.value.activeGame);
  const [dragging, setDragging] = useState(false);

  const handleOpen = (item) => {
    if (dragging) return;
    dispatch(SET_ACTIVE_GAME(item));
  };

  const handleClose = () => {
    dispatch(SET_ACTIVE_GAME(null));
  };

  const settings = {
    dots: true,
    arrows: false,
    speed: 500,
    infinite: false,
    centerMode: true,
    centerPadding: "2%",
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
  };

  return (
    <Wrapper>
      <Slider {...settings} className={activeGame ? "game-details" : ""}>
        {data.map((item) => (
          <div key={item.id} className="slide-wrapper">
            <Slide
              item={item}
              handleOpen={handleOpen}
              activeGame={activeGame}
            />
          </div>
        ))}
      </Slider>
      <EventDetail handleClose={handleClose} />
    </Wrapper>
  );
};

EventsSlider.defaultProps = {};

EventsSlider.propTypes = {
  data: PropTypes.array,
};

export default EventsSlider;
