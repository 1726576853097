import React from "react";
import { Routes, Route } from "react-router-dom";

import Auth from "./auth/Routes/auth";
import Login from "./auth/Routes/login";
import EmailSent from "./auth/Routes/emailSent";
import ProtectedRoutes from "./auth/ProtectedRoutes";
import Error from "./pages/Error";
import Home from "./pages/Home";
import History from "./pages/History";
import Profile from "./pages/Profile";
import Game from "./pages/Game";

const App = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<Home />} />
        <Route path="history" element={<History />} />
        <Route path="profile/:userId" element={<Profile />} />
        <Route path="game/:gameId" element={<Game />} />
      </Route>
      <Route path="auth" element={<Auth />} />
      <Route path="login" element={<Login />} />
      <Route path="email-sent" element={<EmailSent />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default App;
