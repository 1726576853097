import { styled } from "@mui/material/styles";

const Wrapper = styled("div")(({ theme }) => ({
  borderRadius: "30px",
  overflow: "hiddden",
  position: "relative",
  zIndex: "0",
  padding: "0 10px",
  "& .bgImg": {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "100%",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "30px",
    filter: "contrast(1.2) brightness(1.2) saturate(1.2) opacity(0.8)",
    zIndex: "-1",
  },
  "& .header-details-wrapper": {
    marginTop: "8px",
    gap: "16px",
  },
  "& .header-details-wrapper .header-details": {
    width: "100%",
  },
  "& .header-details-wrapper .header-avatars > div": {
    width: "100%",
    justifyContent: "flex-start",
  },
  [theme.breakpoints.up("sm")]: {
    "& .header-details-wrapper": {
      flexDirection: "row",
      marginTop: "8px",
      gap: "16px",
    },
    "& .header-details-wrapper .header-details": {
      width: "66%",
    },
    "& .header-details-wrapper .header-avatars > div": {
      width: "33%",
      justifyContent: "center",
    },
  },
}));

export default Wrapper;
