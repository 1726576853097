import { useState, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

import Wrapper from "./Wrapper";
import { theme } from "../../../theme";
import { gameRegistration, cancelGameRegistration } from "../../../services";
import { UPDATE_GAME_DATA, SET_ACTIVE_GAME } from "../../../store/GamesSlice";
import { Arrow, Pin, Calendar } from "../../Svg";
import GameParticipants from "../../GameParticipants";
import PlayersAvatars from "../../PlayersAvatars";
import GameRegistrationSnippet from "../../GameRegistrationSnippet";

const GAME_REGISTRATION_HEIGHT = 160;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EventDetail = (props) => {
  const user = useSelector((state) => state.currentUser.value.data);
  const activeGame = useSelector((state) => state.games.value.activeGame);
  const dispatch = useDispatch();
  const { handleClose } = props;
  const [registrationOpen, setRegistrationOpen] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  useEffect(() => {
    if (activeGame) {
      activeGame.players.map((player) => {
        if (player.id === user.id) setIsRegistered(true);
      });
    } else {
      setIsRegistered(false);
    }
  }, [activeGame]);

  if (!activeGame) return;

  const handleRegistrationOpen = () => {
    setRegistrationOpen(true);
  };

  const handleRegistrationCancel = () => {
    setRegistrationOpen(false);
  };

  const handleRegistration = () => {
    gameRegistration(user.id, activeGame.id)
      .then((res) => {
        dispatch(UPDATE_GAME_DATA(res.data));
        dispatch(SET_ACTIVE_GAME(res.data.id));
      })
      .then(() => {
        setRegistrationOpen(false);
      });
  };

  const handleCancelRegistration = () => {
    cancelGameRegistration(user.id, activeGame.id)
      .then((res) => {
        dispatch(UPDATE_GAME_DATA(res.data));
        dispatch(SET_ACTIVE_GAME(res.data.id));
      })
      .then(() => {
        setRegistrationOpen(false);
      });
  };

  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={activeGame ? true : false}
        onClose={handleClose}
        onOpen={() => {}}
        hideBackdrop
        PaperProps={{
          sx: {
            maxWidth: "calc(600px)",
            margin: "0 auto",
            borderRadius: "30px 30px 0 0",
            overflow: "visible",
          },
        }}
      >
        <Wrapper {...props}>
          <Container
            maxWidth="sm"
            disableGutters
            sx={{
              height: "50vh",
              padding: "1rem 1rem 0",
              position: "relative",
              marginBottom: `${GAME_REGISTRATION_HEIGHT}px`,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "-15%",
                left: "5%",
                width: "40px",
                height: "40px",
              }}
            >
              <Button
                className="game-details-back"
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: "rgba(150,150,150,.5)",
                  borderRadius: "50%",
                  width: "100%",
                  height: "100%",
                  minWidth: "40px",
                  maxWidth: "40px",
                  padding: "0",
                  boxShadow: "none",
                }}
              >
                <Arrow
                  strokeColor={theme.colors.white.default}
                  strokeWidth="1"
                />
              </Button>
            </Box>
            <Box
              sx={{
                position: "relative",
                height: "calc(100% - 1rem)",
                padding: "1rem 0 0",
              }}
            >
              <Box
                sx={{
                  width: "100px",
                  height: "6px",
                  backgroundColor: theme.colors.black.default,
                  borderRadius: "3px",
                  position: "absolute",
                  top: "0",
                  left: "calc(50% - 50px)",
                }}
              />
              <Stack
                sx={{
                  height: "100%",
                  overflowY: "scroll",
                  padding: "1rem 0 0",
                }}
                spacing={4}
              >
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "1rem",
                    padding: "0 2rem",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "700",
                    }}
                  >
                    {activeGame.name}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    justifyContent: "space-between",
                    padding: "0 2rem",
                  }}
                  spacing={2}
                >
                  <Stack
                    direction="row"
                    sx={{ gap: ".5rem", alignItems: "center" }}
                  >
                    <Pin strokeColor={theme.colors.secondary.default} />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        lineHeight: "1",
                      }}
                    >
                      {activeGame.venue}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{ gap: ".5rem", alignItems: "center" }}
                  >
                    <Calendar strokeColor={theme.colors.secondary.default} />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        lineHeight: "1",
                      }}
                    >
                      {moment(activeGame.date.timestamp).format("MMM Do, YYYY")}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: "space-around",
                    padding: "1.25rem 1rem",
                    backgroundColor: "rgba(235,235,235, 1)",
                    borderRadius: "10px",
                  }}
                >
                  <Stack sx={{ alignItems: "center" }} spacing={1}>
                    <Typography
                      variant="subtitle2"
                      sx={{ letterSpacing: "3px" }}
                    >
                      START
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: "700" }}>
                      {moment(activeGame.date.timestamp).format("HH:mm")}
                    </Typography>
                  </Stack>
                  <Stack sx={{ alignItems: "center" }} spacing={1}>
                    <Typography
                      variant="subtitle2"
                      sx={{ letterSpacing: "3px" }}
                    >
                      END
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: "700" }}>
                      {moment(activeGame.date.timestamp)
                        .add(activeGame.date.duration, "ms")
                        .format("HH:mm")}
                    </Typography>
                  </Stack>
                </Stack>
                <GameParticipants
                  players={activeGame.players}
                  teams={activeGame.teams}
                  maxPlayers={activeGame.maxPlayers}
                  avatarSize={70}
                  handleIncognitoPlayerClick={handleRegistrationOpen}
                />
              </Stack>
            </Box>
          </Container>
          <GameRegistrationSnippet
            players={activeGame.players}
            teams={activeGame.teams}
            maxPlayers={activeGame.maxPlayers}
            handleRegistrationOpen={handleRegistrationOpen}
            height={GAME_REGISTRATION_HEIGHT}
            isRegistered={isRegistered}
          />
        </Wrapper>
      </SwipeableDrawer>
      <Dialog
        fullScreen
        open={registrationOpen}
        onClose={() => {}}
        TransitionComponent={Transition}
      >
        <Container maxWidth="sm" sx={{ height: "100%" }}>
          <Stack
            spacing={4}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "700" }}>
              Event Registration
            </Typography>
            <Stack spacing={0.5} sx={{ width: "100%" }}>
              <Stack direction="row">
                <Typography variant="subtitle1" sx={{ fontWeight: "700" }}>
                  Name:&nbsp;
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.colors.secondary.default }}
                >
                  {activeGame.name}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Typography variant="subtitle1" sx={{ fontWeight: "700" }}>
                  Location:&nbsp;
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.colors.secondary.default }}
                >
                  {activeGame.venue}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Typography variant="subtitle1" sx={{ fontWeight: "700" }}>
                  Date:&nbsp;
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.colors.secondary.default }}
                >
                  {moment(activeGame.date.timestamp).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Typography variant="subtitle1" sx={{ fontWeight: "700" }}>
                  Time:&nbsp;
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.colors.secondary.default }}
                >
                  {moment(activeGame.date.timestamp).format("HH:mm")}
                </Typography>
              </Stack>
            </Stack>
            <Stack spacing={2} sx={{ width: "100%" }}>
              {activeGame.players.length !== 0 && (
                <>
                  <Typography variant="subtitle1" sx={{ fontWeight: "700" }}>
                    You&apos;ll play with
                  </Typography>
                  <PlayersAvatars
                    players={activeGame.players}
                    maxPlayers={activeGame.maxPlayers}
                    avatarSize={40}
                  />
                </>
              )}
            </Stack>
            <Stack spacing={1} sx={{ width: "100%" }}>
              <Button
                variant="contained"
                sx={{
                  fontWeight: "700",

                  backgroundColor: isRegistered
                    ? "red"
                    : theme.colors.secondary.default,
                  color: theme.colors.white.default,
                }}
                onClick={
                  isRegistered ? handleCancelRegistration : handleRegistration
                }
              >
                {isRegistered ? "Cancel Registration" : "Register"}
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={handleRegistrationCancel}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Dialog>
    </>
  );
};

EventDetail.defaultProps = {};

EventDetail.propTypes = {
  activeGame: PropTypes.object,
  handleClose: PropTypes.func,
};

export default EventDetail;
