import React from "react";
import { Link } from "react-router-dom";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const Error = () => {
  return (
    <Container disableGutters sx={{ position: "relative", height: "100vh" }}>
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
        spacing={10}
      >
        <Typography
          variant="h1"
          component="h2"
          sx={{
            fontSize: "10rem",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          404
        </Typography>
        <Typography
          variant="h4"
          sx={{
            lineHeight: "1.5",
            textAlign: "center",
          }}
        >
          Hey Player! Looks like you are heading to the wrong court!
        </Typography>
        <Button component={Link} variant="contained" to="/">
          Take me back to the homepage
        </Button>
      </Stack>
    </Container>
  );
};

export default Error;
